<template>
  <div class="library-slider" v-if="items && items.length">
    <div class="content">
      <div class="d-flex mb-8">
        <div
          class="library-slider__title mr-8 mb-0 d-flex"
          v-if="title"
          v-html="title"
        ></div>
        <div class="library-slider__title mr-8 mb-0" v-else>
          Дополнительные материалы по теме:
          <div
            :style="`${this.nosology.active ? 'color:'+color+'; cursor: pointer' : 'color:'+color}`"
            :class="{'library-slider__title_highlight': this.nosology.active}"
            v-if="nosology && nosology.slug"
            @click="
              toNosol({
                name: 'NosologyDetail',
                params: { slug: nosology.slug },
              })
            "
            v-html="nosology.title"
          ></div>
          <span
            :style="`color: ${color}`"
            class="library-slider__title_highlight"
            v-else-if="nosology"
            v-html="nosology.title"
          >
          </span>
        </div>
        <div
          class="library-slider__arrows mt-0 ml-auto d-none d-md-flex"
          v-if="showArrows"
        >
          <div
            class="library-slider__arrow library-slider__arrow_prev mr-3"
            :style="` --color-hover: ${color}`"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.25 4.50012L3.75 12.0001L11.25 19.5001"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
              <path
                d="M5.25 12.0001L20.25 12.0001"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </div>
          <div
            class="library-slider__arrow library-slider__arrow_next ml-3"
            :style="` --color-hover: ${color}`"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.75 4.50012L20.25 12.0001L12.75 19.5001"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
              <path
                d="M18.75 12.0001L3.75 12.0001"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
      </div>

      <swiper v-bind="swiperSettings" :key="items.length">
        <swiper-slide v-for="(i, ind) in items" :key="ind">
          <div class="material-wrap">
            <Project
              v-if="
                i.component &&
                (i.component === 'project' || i.component === 'archive-event')
              "
              :item="{ ...i, type: { name: 'Проекты' } }"
            />
            <Material
              v-else
              :video="video"
              :item="i"
              :page="page || i.page"
              @clickMaterials="clickMaterials"
            />
          </div>
        </swiper-slide>
      </swiper>
      <div class="library-slider__arrows d-md-none" v-if="showArrows">
        <div
          class="library-slider__arrow library-slider__arrow_prev mr-3"
          :style="` --color-hover: ${color}`"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 4.50012L3.75 12.0001L11.25 19.5001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M5.25 12.0001L20.25 12.0001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
        <div
          class="library-slider__arrow library-slider__arrow_next ml-3"
          :style="` --color-hover: ${color}`"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.75 4.50012L20.25 12.0001L12.75 19.5001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M18.75 12.0001L3.75 12.0001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import { Navigation } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import Material from "./Material.vue";
import Project from "@/components/pageComponents/Project.vue";
SwiperCore.use([Navigation]);
export default {
  name: "LibrarySlider",
  props: {
    items: Array,
    nosology: [Object, String],
    video: {
      type: Boolean,
      default: false,
    },
    page: String,
    color: { type: String, default: "#830051" },
    title: String,
  },
  components: {
    Swiper,
    SwiperSlide,
    Project,
    Material,
  },
  data: () => ({
    showArrows: true,
    swiperSettings: {
      slidesPerView: 3,
      spaceBetween: 32,
      loop: true,
      navigation: {
        nextEl: ".library-slider__arrow_next",
        prevEl: ".library-slider__arrow_prev",
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
        1220: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
        // when window width is >= 640px
      },
    },
  }),
  computed: {},
  methods: {
    onInitCarousel() {
      this.showArrows =
        this.$refs.librarySlider?.settings.slidesToShow < this.items.length;
    },
    clickMaterials(name) {
      this.$emit("clickMaterials", name);
    },
    toNosol(to) {
      if (this.nosology.active) {
        this.$emit("toNosol", this.nosology.title, to);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.library-slider {
  margin-bottom: -124px;
  padding-bottom: 124px;
  margin-top: 64px;
  padding-top: 64px;
  background-color: #f8f8f8;
  @media screen and (max-width: 1220px) {
    padding-top: 32px;
    margin-top: 32px;
    padding-bottom: 120px;
    margin-bottom: -120px;
  }

  &__title {
    margin-bottom: 32px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }

    &_highlight {
      text-decoration: underline;
      color: #830051;
      transition: 0.3s;
    }
  }

  .material {
    height: 100%;
  }

  .project-item {
    height: 100%;
  }

  .material-wrap {
    height: 100%;
  }

  &__arrows {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__arrow {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #d8dada;
    cursor: pointer;
    color: #8b8e8e;
    transition: 0.3s;

    &:hover {
      color: var(--color-hover);
      background-color: #ebefee;
    }
  }
}
</style>

<style lang="scss">
.library-slider {
  .swiper-slide {
    height: auto !important;
  }
}
</style>